<template>
  <module
    ref="module"
    id="billingwallet"
    titleIcon="nc-icon nc-money-coins"
    :title="$t('walletbilling')"
    :use-default-list="false"
  >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">

        <!-- Modal for topup -->
        <modal :required="true" :show.sync="modal" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up" v-if="topup">{{ $t('topuptitle') }}</h4>
          <h4 slot="header" class="title title-up" v-else>{{ $t('postchargetitle') }}</h4>
          <div class="alert alert-info">
            <a class="info" v-if="topup">
              <i class="fa fa-info-circle"></i>
              Apply top-up for user {{ top_up_user }}
            </a>
            <a class="info" v-else>
              <i class="fa fa-info-circle"></i>
              Post charge for user {{ top_up_user }}
            </a>

          </div>
          <div :class="`alert-${modal_alert_type}`" class="alert alert-dismissible fade show" v-if="modal_alert">
            <button type="button" aria-hidden="true" class="close" data-dismiss="alert" aria-label="Close"
                    v-on:click="closeModalAlert">
              <i class="nc-icon nc-simple-remove"></i>
            </button>
            <span>{{ modal_alert_msg }}</span>
          </div>

          <div class="row">
            <div class="col-6">
              <label v-if="topup">
                {{ $t('topuptitle') }} {{ $t('amount') }}
              </label>
              <label v-else>
                {{ $t('charge') }} {{ $t('amount') }}
              </label>

              <fg-input v-model="topup_value" addon-left-icon="nc-icon nc-money-coins" type="text"></fg-input>
            </div>
            <div class="col-6">
              <div v-if="topup">
                <label> {{ $t('methodsavailable') }} </label>
                <select class="form-control"
                        v-model="topup_description">
                  <optgroup label="Manual">
                    <option v-for="item in manualMethods"
                            :key="item.id"
                            :label="item.description"
                            :value="item.id">
                    </option>
                  </optgroup>
                  <optgroup label="Automatic" v-if="automaticMethods.length > 0">
                    <option v-for="item in automaticMethods"
                            :key="item.id"
                            :label="item.description"
                            :value="item.id">
                    </option>
                  </optgroup>
                </select>
              </div>
              <div v-else>
                <label> {{ $t('methodsavailable') }} </label>

                <select class="form-control"
                        v-model="topup_description">
                  <optgroup label="Manual">
                    <option v-for="item in manualMethodsDebit"
                            :key="item.id"
                            :label="item.description"
                            :value="item.id">
                    </option>
                  </optgroup>
                  <optgroup label="Automatic" v-if="automaticMethodsDebit.length > 0">
                    <option v-for="item in automaticMethodsDebit"
                            :key="item.id"
                            :label="item.description"
                            :value="item.id">
                    </option>
                  </optgroup>
                </select>

              </div>

            </div>
          </div>
          <br>
          <div class="row form-group" v-if="topup">
            <div class="col-12">
              <textarea v-model="topup_notes" id="description" class="form-control" :placeholder="desctopup"
                        rows="3"></textarea>
            </div>
          </div>

          <div class="row form-group" v-if="topup == false">
            <div class="col-12">
              <textarea v-model="pccomment" class="form-control" :placeholder="pcdesc" rows="5"></textarea>
            </div>
          </div>

          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="left-side" v-if="modalinprogress == false">
              <p-button @click.prevent="validate" link v-if="topup">{{ $t('topuptitle') }}</p-button>
              <p-button @click.prevent="validate" link v-else>{{ $t('postchargetitle') }}</p-button>
            </div>
            <div class="divider" v-if="modalinprogress == false"></div>
            <div class="right-side" v-if="modalinprogress == false">
              <p-button type="danger" v-on:click="closeModalAlert" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
        </modal>

        <!-- Modal for Address Info -->
        <modal :required="true" :show.sync="modalinfo" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up" v-if="topup">{{ $t('account') }} {{ $t('infotxt') }}</h4>

          <div class="row">
            <div class="col-md-10 mx-auto text-center">
              <blockquote>
                {{ uinfo.address }}<br/>
                {{ uinfo.city }}, {{ uinfo.postal }}<br/>
                {{ uinfo.country }}<br/>
              </blockquote>
            </div>
          </div>

          <template slot="footer">
            <p-button type="info" @click.prevent="modalinfo = false" link>{{ $t('gotit') }}</p-button>
          </template>
        </modal>

        <!-- Modal for Become a Seller/Buyer -->
        <become-seller-buyer></become-seller-buyer>
        <modal :required="true" :show.sync="modalBecomeSellerBuyer" headerClasses="justify-content-center"
               modalClasses="modal-lg">
          <h4 slot="header" class="title title-up">{{ txt.availableCurr }}</h4>
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <span>{{ txt.usdTxt }}</span>
                <p-checkbox v-model="sellerBuyerUsd"/>
              </div>
              <div class="col-md-4">
                <span>{{ txt.eurTxt }}</span>
                <p-checkbox v-model="sellerBuyerEur"/>
              </div>
              <div class="col-md-4">
                <span>{{ txt.brlTxt }}</span>
                <p-checkbox v-model="sellerBuyerBrl"/>
              </div>
            </div>
          </div>
          <template slot="footer">
            <div class="left-side">
              <p-button type="success" @click.prevent="saveAndCloseModal()" link>{{ txt.savechanges }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalBecomeSellerBuyer = false" link>{{ txt.cancel }}</p-button>
            </div>
          </template>
        </modal>

        <!-- Modal for generate a reset password link -->
        <modal :required="true" :show.sync="genLinkModal">
          <template slot="header">
            <h4>Generate new reset password link</h4>
          </template>

          <div v-if="resetState == 1" class="text-center">
            Are you sure to generate a new reset password link for the user?
            <b>{{ userid_ref }}</b>
          </div>
          <div v-if="resetState == 2" class="text-center">
            <h2>{{ $t('submitting') }}</h2>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>
          <div v-if="resetState == 3" class="text-center">
            <div class="col-md-12 text-center">
              New link is generated, copy to clipboard in the button bellow.
            </div>
            <div class="col-md-12 text-center" style="margin-top: 20px">
              <a class="clickable" v-on:click="cp_clipboard" style="width: 50px; display: inline-block">
                <i class="fa fa-copy"></i>
              </a>
            </div>
            <input id="clipit" type="text" v-model="newlink" style="display: none">
          </div>
          <div v-if="resetState == 4" class="text-center">
            Something went wrong, call the cops!
          </div>

          <template slot="footer" v-if="resetState == 1">
            <div class="right-side">
              <p-button
                type="success"
                link
                v-on:click="requestNewLink()">
                <span>{{ $t('confirm') }}</span>
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="left-side">
              <p-button
                type="danger"
                link
                v-on:click="genLinkModal = false">
                {{ $t('cancel') }}
              </p-button>
            </div>
          </template>
          <template slot="footer" v-if="resetState == 2">
            <div class="right-side">
              <p-button type="default" link>
                <span>{{ $t('confirm') }}</span>
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="left-side">
              <p-button type="default" link>{{ $t('cancel') }}</p-button>
            </div>
          </template>
          <template slot="footer" v-if="resetState == 3">
            <p-button
              type="success"
              link
              v-on:click="genLinkModal = false">
              {{ $t('gotit') }}
            </p-button>
          </template>
          <template slot="footer" v-if="resetState == 4">
            <p-button
              type="danger"
              link
              v-on:click="genLinkModal = false">
              {{ $t('gotit') }}
            </p-button>
          </template>
        </modal>

        <!-- Modal to update user affiliate -->
        <modal :required="true" :show.sync="modalAffiliate">
          <template slot="header">
            <h4> Insert a new affiliate email </h4>
          </template>
          <div class="alert alert-info">
            <a class="info">
              <i class="fa fa-info-circle"></i>
            </a>
            You are changing the affiliate for user {{ userForAffUpdate }}
          </div>

          <div class="row">
            <div class="col-md-12">
              <label> Current Affiliate </label>
              <fg-input v-model="current_affiliate" type="email"
                        placeholder="email@domain.com"
                        v-on:keyup="recipientHandler">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <fg-numeric
                v-model="affiliate_per"
                :min="min"
                :max="max"
                :step="step"
                :precision="precision"
                :label="$t('affiliatePercentage')"
              ></fg-numeric>

            </div>
          </div>

          <template slot="footer">
            <div class="right-side">
              <p-button
                type="success"
                link
                v-on:click="preSearchUser">
                <span> {{ $t('confirm') }}</span>
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="left-side">
              <p-button
                type="danger"
                link
                v-on:click="modalAffiliate = false">
                {{ $t('cancel') }}
              </p-button>

            </div>
          </template>
        </modal>

        <!-- Modal to remove user  -->
        <modal :required="true" :show.sync="removeUserModal">
          <template slot="header">
            <h4> {{ $t('removetxt') }} {{ $t('usertext') }} </h4>
          </template>
          <div class="row">
            <div class="col-md-12" style="text-align: center">
              <p><b> {{ $t('sureRemoveUser') }}</b></p>
            </div>
            <div class="col-md-12">
              <p><b> {{ $t('nametxt') }}: </b>{{ removeUserData.name }}</p>
              <p><b> {{ $t('userid') }}: </b>{{ removeUserData.userid }}</p>
            </div>
          </div>

          <template slot="footer">
            <div class="py-3 mx-auto" v-if="modalinprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="right-side" v-if="modalinprogress == false">
              <p-button
                type="success"
                link
                v-on:click="removeUser">
                <span> {{ $t('confirm') }}</span>
              </p-button>
            </div>
            <div class="divider" v-if="modalinprogress == false"></div>
            <div class="left-side" v-if="modalinprogress == false">
              <p-button
                type="danger"
                link
                v-on:click="removeUserModal = false">
                <span> {{ $t('cancel') }}</span>
              </p-button>
            </div>

          </template>
        </modal>

        <!-- Modal change transfer limit  -->
        <modal :required="true" :show.sync="transferLimitModal"
               headerClasses="justify-content-center"
               modalClasses="modal-lg">
          <template slot="header">
            <h4 class="title title-up">Change transfer limit</h4>
          </template>
          <div class="row">
            <div class="col-2"></div>
            <div class="col">
              <div>
                <b>{{ $t('usertext') }} &nbsp;</b>:
                {{transferLimitData.first_name}} {{transferLimitData.last_name}} - {{transferLimitData.userid}}
              </div>
              <hr>
              <div>
                <label> Transfer Limit </label>
                <fg-input v-model="transferLimitData.transfer_limit" type="number"></fg-input>
              </div>
            </div>
            <div class="col-2"></div>
          </div>
          <template slot="footer">
            <div class="py-3 mx-auto" v-if="transferLimitModalInprogress">
              <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
            </div>
            <div class="left-side" v-if="transferLimitModalInprogress == false">
              <p-button
              type="default"
              link
              v-on:click="transferLimitModal = false">
              {{ $t('cancel') }}
            </p-button>
            </div>
            <div class="divider" v-if="transferLimitModalInprogress == false"></div>
            <div class="right-side" v-if="transferLimitModalInprogress == false">
              <p-button type="success" link v-on:click="setNewTransferLimit(transferLimitData)"> Set new limit</p-button>
            </div>
          </template>

        </modal>

        <div class="col-md-12">
          <!--
          <div class="card-header">
            <div class="category">Extended tables</div>
          </div>
          -->
          <div class="row">

            <div class="col-sm-2">
              <div>
                <label>{{ sortbyTxt }}</label>
              </div>
              <el-select v-model="sortby.column" :placeholder="sortbyTxt"
                         v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.columns"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <div>
                <label>{{ perpageTxt }}</label>
              </div>
              <el-select
                v-model="pagination.perPage" :placeholder="perpageTxt">
                <el-option class="select-default"
                           v-for="item in pagination.perPageOptions"
                           :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <div>
                <label>{{ sortbyOrder }}</label>
              </div>
              <el-select
                v-model="sortby.order" :placeholder="sortbyOrder"
                v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.orders"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>


            <div class="col-sm-6">
              <div class="pull-right">
                <label> {{ searchTxt }} </label>
                <fg-input class="input-sm"
                          :placeholder="searchTxt"
                          v-model="searchQuery"
                          addon-right-icon="nc-icon nc-zoom-split">
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <table class="table table-bordered table-bordered table-condensed table-x">
                <thead style="color: silver!important;">
                <tr>
                  <th class="text-center"> {{ actionlbl }}</th>
                  <th class="text-center"> {{ this.$t('userid') }}</th>
                  <th class="text-center"> {{ this.$t('nametxt') }}</th>
                  <th class="text-center"> {{ this.$t('sign_up_dt_txt') }}</th>
                  <th class="text-center"> {{ this.$t('currentbalance') }}</th>
                  <th class="text-center"> {{ this.$t('number_of_accountstxt') }}</th>
                  <th class="text-center"> {{ this.$t('number_of_vps') }}</th>
                  <th class="text-center"> {{ this.$t('last_login') }}</th>
                  <th class="text-center"> {{ this.$t('affiliatetxt') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="data in queriedData">
                  <td class="text-center">
                    <drop-down tag="li"
                               position="right"
                               class="col-md-12"
                               direction="none"
                               style="margin-top: 19px">
                      <p-button slot-scope="{isOpen}" slot="title" type="default" round icon
                                class="dropdown-toggle no-caret"
                                :aria-expanded="isOpen">
                        <i class="nc-icon nc-settings-gear-65"></i>
                      </p-button>
                      <a class="dropdown-item"
                         :href="userAccountPage(`${data.userid}`)"
                      >
                        {{ $t('accountstxt') }}
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="userAffiliate(data)"
                      >
                        {{ $t('affiliatetxt') }}
                      </a>
                      <a class="dropdown-item"
                         :href="userInOut(`${data.userid}`)"
                      >
                        {{ $t('statementtxt') }}
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="userTopUp(`${data.userid}`)"
                      >
                        {{ $t('topuptitle') }}
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="userPostCharge(`${data.userid}`)"
                      >
                        {{ $t('postchargetitle') }}
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="userAddress(`${data.userid}`)"
                      >
                        {{ $t('address') }}
                      </a>
                      <a class="dropdown-item"
                         :href="userFollowersPage(`${data.userid}`)"
                      >
                        {{ followerstxt }}
                      </a>
                      <a class="dropdown-item"
                         :href="userStatsPage(`${data.userid}`)"
                      >
                        {{ stats_txt }}
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="openSellerBuyerModal(data)"
                      >
                        Seller/Buyer
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="genNewPasswordLink(data)"
                      >
                        Reset Password Link
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="modalRemoveUser(data)"
                      >
                        Remove User
                      </a>
                      <a class="dropdown-item"
                         href="#"
                         v-on:click="changeTransferLimit(data)"
                      >
                        Transfer Limit
                      </a>
                    </drop-down>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.userid }}
                      </span>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.fullname }}
                      </span>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.reg_dt }}
                      </span>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.balance }}
                      </span>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.qtd_accounts }}
                      </span>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.vms }}
                      </span>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.last_login }}
                      </span>
                  </td>
                  <td class="text-center">
                     <span>
                        {{ data.affiliateUser }}
                      </span>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </module>

</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option, Tooltip} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {Button, Modal} from 'src/components/UIComponents'
import swal from "sweetalert2";
import {Slider} from 'element-ui'
import {mapActions, mapState} from "vuex";
import BecomeSellerBuyer from "@/components/Dashboard/Views/Dashboard/Billing/BecomeSellerBuyer";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(require('vue-moment'))

let bills = [];
let timeoutcount = 5000;

export default {
  components: {
    BecomeSellerBuyer,
    PPagination, [Button.name]: Button, Modal,
    [Tooltip.name]: Tooltip,
    [Slider.name]: Slider
  },
  computed: {
    ...mapState('becomeSellerBuyer', ['showBecomeSellerBuyerModal', 'user']),
    pagedData() {
      return this.tableData.slice(this.from, this.to)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            //let rowValue = row[key].toString()
            let searchRegex = new RegExp(this.searchQuery, 'gi');
            //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            if (row[key] !== null && row[key].toString().match(searchRegex) !== null) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data() {
    return {
      userid_ref: '',
      genLinkModal: false,
      resetState: 1,
      newlink: '',
      stats_txt: this.$t('stats_txt'),
      followerstxt: this.$t('followerstxt'),
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      sortbyOrder: this.$t('sortbyorder'),
      searchTxt: this.$t('searchtxt'),
      colSort: 'userid',
      inprogress: true,
      inprogresstxt: this.$t('loadingwalletbill'),
      actionlbl: this.$t('action'),
      modalinfo: false,
      modalSellerBuyer: false,
      sortby: {
        column: 'userid',
        order: 'asc',
        orders: [
          {
            prop: 'asc',
            label: this.$t('ascending')
          },
          {
            prop: 'desc',
            label: this.$t('descending')
          }
        ],
        columns: [
          {
            prop: 'userid',
            label: this.$t('userid')
          },
          {
            prop: 'fullname',
            label: this.$t('nametxt')
          },
          {
            prop: 'reg_dt',
            label: this.$t('sign_up_dt_txt')
          },
          {
            prop: 'balance',
            label: this.$t('currentbalance')
          },
          {
            prop: 'qtd_accounts',
            label: this.$t('number_of_accountstxt')
          },
          {
            prop: 'vms',
            label: this.$t('number_of_vps')
          },
          {
            prop: 'affiliateUser',
            label: this.$t('affiliatetxt')
          }
        ]
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['userid', 'fullname', 'affiliateUser'],
      tableColumns: [
        {
          prop: 'userid',
          label: this.$t('userid'),
          minWidth: 150
        },
        {
          prop: 'fullname',
          label: this.$t('nametxt')
        },
        {
          prop: 'balance',
          label: this.$t('currentbalance')
        },

        {
          prop: 'balance_endofnextmonth',
          label: this.$t('eonmbalance')
        },
        {
          prop: 'affiliateUser',
          label: this.$t('affiliatetxt')
        }
      ],
      tableData: bills,
      modal: false,
      modalAffiliate: false,
      current_affiliate: "",
      new_affiliate: "",
      searchu: false,
      modal_alert: false,
      modal_alert_msg: null,
      modal_alert_type: 'danger',
      top_up_user: null,
      topup_value: 0,
      topup_description: 'Bonus',
      topup_notes: "",
      modalinprogress: false,
      topup: true,
      manualMethods: [],
      automaticMethods: [],
      allMethods: [],
      manualMethodsDebit: [],
      automaticMethodsDebit: [],
      allMethodsDebit: [],
      pcmethods: ['VPS', 'MANUAL', 'TRANSFER', 'WITHDRAWAL', 'SIGNAL', 'OVERDUE'],
      pccomment: null,
      pcdesc: this.$t('postchargecomment'),
      desctopup: this.$t('topupdesc'),
      uinfo: {'address': null, 'city': null, 'postal': null, 'country': null},
      //  Seller/Buyer
      modalBecomeSellerBuyer: false,
      sellerBuyerUsd: false,
      sellerBuyerEur: false,
      sellerBuyerBrl: false,
      marketplaceEntry: {
        currency: "",
        description: "",
        exchange: 1.0,
        max_sale: 50000,
        min_sale: 50,
        type: "",
        userid: "",
        email: "",
      },
      txt: {
        usdTxt: "$ USD",
        eurTxt: "€ EUR",
        brlTxt: "R$ BRL",
        availableCurr: this.$t('availablecurrencies'),
        savechanges: this.$t('savechanges'),
        cancel: this.$t('cancel')
      },
      type: ["sell", "buy"],
      existMktBuyEntry: false,
      existMktSellEntry: false,
      responseBuyApi: [],
      responseSellApi: [],
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      userForAffUpdate: "",
      removeUserModal: false,
      removeUserData: {
        name: '',
        userid: '',
      },
      affiliate_per: 20,
      min: 0,
      max: 100,
      step: 1,
      precision: 0,
      transferLimitModal: false,
      transferLimitData: {},
      transferLimitModalInprogress: false,
    }
  },
  methods: {
    ...mapActions('becomeSellerBuyer', ['openBecomeSellerBuyerModal', 'setUser', 'setMarketplaceData']),
    cleanit(val) {
      if (val === '') {
        val = 0;
      } else {
        val = String(val).split(" ");
        val = val[0];
      }

      val = val.replace(/USD/g, "");
      val = val.replace(/\$/g, "");

      if (val == '') {
        val = 0;
      }

      val = parseFloat(val);

      return val;
    },
    closeModalAlert() {
      this.modal = false;
      this.modal_alert = false;
      this.modal_alert_msg = null;
      this.topup = true;
      this.pccomment = null;
      this.top_up_user = null;
      this.topup_value = 0;
      this.topup_description = 'Bonus';
      this.topup_notes = '';
      this.modalinprogress = false;
    },
    loadProjection(resp) {
      if (!resp.success) {
        return;
      }
      let data = resp.data;

      bills = []
      for (var i = 0; i < data.length; i++) {
        if (data[i]['affiliateUser'] === null) {
          data[i]['affiliateUser'] = '';
        }
        if (data[i]['qtd_accounts'] === null) {
          data[i]['qtd_accounts'] = 0;
        }
        data[i]['fullname'] = data[i].first_name + " " + data[i].last_name;
        data[i]['vms'] = data[i].qtd_vm + data[i].qtd_wl_vms
        let bill = data[i];
        bills.push(data[i]);
      }
      // Sort and Overwrite Table
      bills.sort(this.sortIt);
      this.tableData = bills;
      // Show Table
      this.inprogress = false;
    },
    sortItNum(a, b) {
      if (this.sortby.order == 'asc') {
        return a[this.colSort] - b[this.colSort];
      }
      return b[this.colSort] - a[this.colSort];
    },
    sortIt(a, b) {
      if (this.sortby.order == 'asc') {
        if (a[this.colSort] < b[this.colSort])
          return -1;
        if (a[this.colSort] > b[this.colSort])
          return 1;
      }
      if (b[this.colSort] < a[this.colSort])
        return -1;
      if (b[this.colSort] > a[this.colSort])
        return 1;
      return 0;
    },
    sortByCol() {
      this.colSort = this.sortby.column;
      if (this.sortby.column == 'userid' || this.sortby.column == 'fullname'
        || this.sortby.column == 'affiliateUser' || this.sortby.column == 'reg_dt') {
        bills.sort(this.sortIt);
      } else {
        bills.sort(this.sortItNum);
      }
    },

    userAddress(userid) {
      let uidx = this.tableData.findIndex(b => b.userid === userid);
      this.uinfo = {
        'address': null, 'city': null, 'postal': null, 'country': null
      };

      if (uidx >= 0) {
        this.modalinfo = true;
        this.uinfo = {
          'address': this.tableData[uidx].address,
          'city': this.tableData[uidx].city,
          'postal': this.tableData[uidx].postal,
          'country': this.tableData[uidx].country
        }
      } else {
        this.modalinfo = false;
      }
    },
    userFollowersPage(userid) {
      return "/affiliate/" + userid + "/followers/";
    },
    userStatsPage(userid) {
      return "/affiliate/" + userid + "/stats/";
    },
    userAccountPage(userid) {
      return "/admin_accounts/" + userid;
    },
    userInOut(userid) {
      return "/billing/inout/" + userid;
    },
    userDeposit(userid) {
      location.href = "/billing/deposits/" + userid;
    },
    userDebit(userid) {
      location.href = "/billing/debits/" + userid;
    },
    userTopUp(userid) {
      //console.log("Here"+ userid);
      this.modal = true;
      this.top_up_user = userid;
    },
    userPostCharge(userid) {
      this.modal = true;
      this.top_up_user = userid;
      this.topup = false;
      this.topup_description = 'MANUAL';
    },
    failop(error) {
      console.log(error);
    },
    validate() {
      let topup = this.cleanit(this.topup_value);
      this.modal_alert = false;
      this.modalinprogress = true;

      const successop = resp => {
        if (!resp.success) {
          return;
        }
        swal(this.$t('successtxt'), this.$t('topup_success'), 'success')
        this.closeModalAlert()
      };
      const failop_xhr = error => {
        this.modalinprogress = false;

        if (error.data !== undefined && error.data !== null && error.data.msg !== undefined && error.data.msg !== null) {
          this.modal_alert_msg = error.data.msg;
        } else {
          if (this.topup) {
            swal(this.$t('attentiontxt'), this.$t('topup_fail_xhr'), 'error')
          } else {
            swal(this.$t('attentiontxt'), this.$t('topup_fail_xhr'), 'error')
          }
        }
      };

      if (topup <= 0 || topup === null || topup === undefined || topup == "" || topup == 'NaN') {
        this.modalinprogress = false;
        if (this.topup) {
          swal(this.$t('attentiontxt'), this.$t('topup_fail'), 'error')
        } else {
          swal(this.$t('successtxt'), this.$t('topup_success'), 'success')
        }
      } else {
        if (this.topup) {
          let ledger_type = this.topup_description;
          let desc = this.allMethods.filter(e => e.id == ledger_type)

          this.$topUpUser(
            this.top_up_user,
            {
              "value": topup,
              "description": desc[0].description + ": " + this.topup_notes,
              "ledger_type": ledger_type
            }
          ).then(successop, failop_xhr);
        } else {
          if (this.pccomment === null || this.pccomment.length === 0 || this.pccomment === '') {
            this.pccomment = this.topup_description;
          }
          this.$postChargeUser(
            this.top_up_user,
            {
              "amount": topup,
              "product": this.topup_description,
              "comment": this.pccomment
            }
          ).then(successop, failop_xhr);
        }
      }
    },
    userAffiliate(data) {
      this.userForAffUpdate = data.userid
      this.current_affiliate = data.affiliateUser
      this.affiliate_per = data.affiliate_per * 100
      this.modalAffiliate = true
    },
    preSearchUser() {
      if (this.current_affiliate.length == 0) {
        this.changeUserAffiliate()
      } else {
        this.findUser(this.current_affiliate)
      }
    },
    findUser(resp) {
      this.$checkIfUserExists(resp).then(this.searchUser, this.userNotfound)
    },
    searchUser(resp) {
      if (resp.success) {
        // this.$toast.info("User found, now you can confirm your transfer to a new affiliate");
        this.changeUserAffiliate()
      } else {
        this.$toast.warning("User not found");
      }
    },
    recipientHandler(event) {
      const eml = this.isEmailValid();
      this.searchu = false;

      if (eml) {
        if (this.new_affiliate.length > 0) {
          this.searchu = true;
        }
      }
    },
    isEmailValid() {
      return (this.new_affiliate == "") ? "" : this.reg.test(this.new_affiliate);
    },
    userNotfound() {
      this.$toast.warning("User not found");
    },
    changeUserAffiliate() {

      const sucess = resp => {
        this.$toast.success(this.$t(resp.msg))

        this.modalAffiliate = false
        this.new_affiliate = ""
        this.affiliate_per = 20
      }

      const error = resp => {
        this.$toast.warning(this.$t(resp.data.msg))
      }

      this.$updateUserAffiliate(
        {
          user_id: this.userForAffUpdate,
          new_affiliate: this.current_affiliate,
          affiliate_per: this.affiliate_per / 100
        }
      ).then(sucess, error)
    },
    genNewPasswordLink(data) {
      this.userid_ref = data.userid;
      this.resetState = 1;
      this.genLinkModal = true;
      this.newlink = '';
    },
    modalRemoveUser(data) {
      this.removeUserData.name = data.first_name + " " + data.last_name
      this.removeUserData.userid = data.userid
      this.removeUserModal = true;
    },
    changeTransferLimit(data) {
      this.transferLimitModal = true
      this.transferLimitData = data
    },
    setNewTransferLimit(data) {
      this.transferLimitModalInprogress = true;
      let param = {
        'userid':  data.userid,
        'transfer_limit':  data.transfer_limit,
      }
      const successTransferLimit = resp => {
        this.transferLimitModalInprogress = this.transferLimitModal = false;

        this.$toast.success(this.$t(resp.msg))
      }
      const failopTransferLimit = resp => {
        this.transferLimitModalInprogress = false;
        this.$toast.warning(this.$t(resp.msg))
      }

      this.$updateTransferLimit(param).then(successTransferLimit, failopTransferLimit);
    },
    removeUser() {
      const success = resp => {
        this.modalinprogress = false
        this.removeUserModal = false
        this.removeUserData.name = ''
        this.removeUserData.userid = ''
        swal(this.$t('successtxt'), this.$t(resp.msg), "success").then(this.refreshPage)
      }

      const error = resp => {
        this.modalinprogress = false
        this.removeUserModal = false
        this.removeUserData.name = ''
        this.removeUserData.userid = ''
        swal(this.$t('attentiontxt'), this.$t(resp.data.msg), "error")
      }
      const removeUser = resp => {
        if (resp.success) {
          this.modalinprogress = true
          this.$removeUser(this.removeUserData.userid).then(success, error)
        }
      }

      this.$validateUserRemovalBroker(this.removeUserData.userid).then(removeUser, error)

    },
    refreshPage() {
      location.reload()
    },
    requestNewLink() {
      this.resetState = 2;
      this.$requestNewLink(this.userid_ref)
        .then(this.successResetHandler, this.errorResetHandler)
    },
    successResetHandler(resp) {
      if (!resp.success) {
        this.resetState = 4;
        return;
      }
      this.resetState = 3;
      this.newlink = resp.data.link;
    },
    errorResetHandler() {
      this.resetState = 4;
    },
    cp_clipboard() {
      let cb = document.getElementById("clipit");
      cb.style.display = 'block';
      cb.select();
      document.execCommand('copy');
      cb.style.display = 'none';
      this.$toast.info('Copied');
    },
    // SellerBuyer
    openSellerBuyerModal(row) {
      this.marketplaceEntry.email = row.userid;
      this.marketplaceEntry.userid = row.userid;
      this.$getMarketEntries_V2(row.userid)
        .then(this.loadMarketplaceEntry, this.failopSellerBuyer);
    },
    loadMarketplaceEntry(resp) {
      let _copiedResp = resp.data
      let buyEntries = []
      let sellEntries = []
      buyEntries = _copiedResp.filter(o => {
        if (o.currency == "BRL") {
          this.sellerBuyerBrl = true;
        }
        if (o.currency == "USD") {
          this.sellerBuyerUsd = true;
        }
        if (o.currency == "EUR") {
          this.sellerBuyerEur = true;
        }
        return (o.type == "buy")
      })

      sellEntries = _copiedResp.filter(o => {
        return (o.type == "sell")
      })

      this.responseBuyApi = buyEntries;
      this.responseSellApi = sellEntries;
      // this.modalBecomeSellerBuyer = true;
      this.setUser(this.marketplaceEntry.userid)
      this.setMarketplaceData(resp)
      this.openBecomeSellerBuyerModal()
    },
    // checkBrl(){
    //     let hasBRL = this.responseBuyApi.some( o => { return o.currency === 'BRL' })
    //     let marketplaceSell = Object.assign({}, this.marketplaceEntry);
    //     let marketplaceBuy = Object.assign({}, this.marketplaceEntry);
    //     if (this.sellerBuyerBrl) {
    //       if(!hasBRL){
    //         marketplaceSell.currency = 'BRL'
    //         marketplaceSell.type = this.type[0]
    //
    //         marketplaceBuy.currency = 'BRL'
    //         marketplaceBuy.type = this.type[1]
    //
    //         const successPostMarketEntry = resp => {
    //           this.$postMarketEntry(marketplaceSell, marketplaceSell, true)
    //           .then(this.marketEntrySucess, this.marketEntryFail)
    //         }
    //
    //         this.$postMarketEntry(marketplaceBuy, marketplaceBuy, true)
    //           .then(successPostMarketEntry, this.marketEntryFail)
    //
    //       }
    //     } else if(hasBRL) {
    //         const successPostMarketEntry = resp => {
    //           this.$delMarketEntry(marketplaceSell, this.type[1], 'BRL')
    //           .then(this.marketEntrySucess, this.marketEntryFail);
    //         }
    //
    //         this.$delMarketEntry(marketplaceBuy, this.type[0], 'BRL')
    //           .then(successPostMarketEntry, this.marketEntryFail);
    //     }
    // },
    // checkUsd(){
    //     let hasUSD = this.responseBuyApi.some( o => { return o.currency === 'USD' })
    //     let marketplaceSell = Object.assign({}, this.marketplaceEntry);
    //     let marketplaceBuy = Object.assign({}, this.marketplaceEntry);
    //     if (this.sellerBuyerUsd) {
    //       if (!hasUSD) {
    //         marketplaceSell.currency = 'USD'
    //         marketplaceSell.type = this.type[0]
    //
    //         marketplaceBuy.currency = 'USD'
    //         marketplaceBuy.type = this.type[1]
    //
    //         const successPostMarketEntry = resp => {
    //             this.$postMarketEntry(marketplaceSell, marketplaceSell, true)
    //               .then(this.marketEntrySucess, this.marketEntryFail)
    //         }
    //
    //         this.$postMarketEntry(marketplaceBuy, marketplaceBuy, true)
    //           .then(successPostMarketEntry, this.marketEntryFail)
    //
    //       }
    //     } else if (hasUSD) {
    //         const successPostMarketEntry = resp => {
    //           this.$delMarketEntry(marketplaceSell, this.type[1], 'USD')
    //             .then(this.marketEntrySucess, this.marketEntryFail)
    //         }
    //
    //         this.$delMarketEntry(marketplaceBuy, this.type[0], 'USD')
    //           .then(successPostMarketEntry, this.marketEntryFail)
    //     }
    // },
    // checkEur(){
    //     let hasEUR = this.responseBuyApi.some( o => { return o.currency === 'EUR' })
    //     let marketplaceSell = Object.assign({}, this.marketplaceEntry);
    //     let marketplaceBuy = Object.assign({}, this.marketplaceEntry);
    //     if (this.sellerBuyerEur) {
    //       if (!hasEUR){
    //         marketplaceSell.currency = 'EUR'
    //         marketplaceSell.type = this.type[0]
    //
    //         marketplaceBuy.currency = 'EUR'
    //         marketplaceBuy.type = this.type[1]
    //
    //         const successPostMarketEntry = resp => {
    //           this.$postMarketEntry(marketplaceBuy, marketplaceBuy, true)
    //             .then(this.marketEntrySucess, this.marketEntryFail)
    //         }
    //         this.$postMarketEntry(marketplaceSell, marketplaceSell, true)
    //           .then(successPostMarketEntry, this.marketEntryFail)
    //
    //       }
    //     } else if (hasEUR) {
    //       const successPostMarketEntry = resp => {
    //         this.$delMarketEntry(marketplaceBuy, this.type[0], 'EUR')
    //         .then(this.marketEntrySucess, this.marketEntryFail)
    //       }
    //       this.$delMarketEntry(marketplaceSell, this.type[1], 'EUR')
    //         .then(successPostMarketEntry, this.marketEntryFail)
    //     }
    // },
    // saveAndCloseModal(){
    //     const self = this
    //     Promise.resolve().then(function(){
    //       self.checkBrl()
    //     }).then(function (){
    //       self.checkUsd()
    //     }).then(function (){
    //       self.checkEur()
    //     }).finally(function (){
    //       self.closeModal()
    //     })
    // },
    // cleanModal(){
    //     this.existMktBuyEntry = false;
    //     this.existMktSellEntry = false;
    //     this.responseBuyApi = [];
    //     this.responseSellApi = [];
    //     this.sellerBuyerBrl = false;
    //     this.sellerBuyerUsd = false;
    //     this.sellerBuyerEur = false;
    //     this.marketplaceEntry = {
    //       currency: "",
    //       description: "",
    //       exchange: 1.0,
    //       max_sale: 50000,
    //       min_sale: 50,
    //       type: "",
    //       userid: "",
    //       email: ""
    //     };
    // },
    // closeModal(){
    //     this.cleanModal()
    //     this.modalBecomeSellerBuyer = false
    // },
    // marketEntrySucess(resp) {
    //   swal(this.$t('successtxt'), this.$t(resp.msg), "success")
    // },
    // marketEntryFail(resp) {
    //   swal(this.$t('attentiontxt'), this.$t(resp.msg), "error")
    // },
    // failopSellerBuyer(error) {
    //   swal(this.$t('attentiontxt'), this.$t('rdpdownmsg'), "error")
    // },
    loadMethods(resp) {
      this.allMethods = resp.data;
      this.manualMethods = resp.data.filter(e => e.apply.includes("M"))
      this.automaticMethods = resp.data.filter(e => e.apply.includes("A"))
    },
    loadMethodsDebit(resp) {
      this.allMethodsDebit = resp.data;
      this.manualMethodsDebit = resp.data.filter(e => e.apply.includes("M"));
      this.automaticMethodsDebit = resp.data.filter(e => e.apply.includes("A"));
    },
  },
  mounted() {
    this.$getWalletProjection()
      .then(this.loadProjection, this.failop);

    this.$getTopupsTypes('P').then(this.loadMethods, this.failop);
    this.$getTopupsTypes('S').then(this.loadMethodsDebit, this.failop);
  },
  created() {
    this.$setTranslatingInProgress()
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress")
  }
}
</script>
<style scoped>
.modal-dialog {
  max-width: 700px;
}
</style>
